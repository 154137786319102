<template>
  <div class="baseBox">
    <div class="base">
      <!-- 工具栏 -->
      <div class="tool">
        <!-- 生产能力 -->
        <div class="product-badges">
          <!-- <slot name="productBadges"></slot> -->
        </div>

        <!-- 隐藏的收藏和详细打开按钮 -->
        <div class="hiddenBtn">
          <div class="detil">
            <my-icon
              class="ico"
              :Iconconfig="Iconconfig"
              chooseIcon="icon-quanping_o"
              @iconBtnClick="openGood(productData.product_id)"
            ></my-icon>
          </div>
          <div class="like" :class="{ haslike: ishasLike }">
            <my-icon
              class="ico"
              :Iconconfig="Iconconfig"
              chooseIcon="icon-like"
              @iconBtnClick="giveLike"
            ></my-icon>
          </div>
        </div>
      </div>

      <div class="hidden" v-if="mode == 'hover'">
   
        <div class="btn" @click="add">
          {{ $t("Goods.Pages.AddToCart") }}
        </div>

        <div class="buyBtn" v-if="!Outofstock && productData.cartnums >= 1">
          <div class="sub" @click="subnum">
            <i class="iconfont icon-jianhao"></i>
          </div>
          <div class="num">
            {{ productData.cartnums }}
          </div>
          <div class="add" @click="addnum">
            <i class="iconfont icon-add"></i>
          </div>
        </div>
        <div class="btn" v-if="Outofstock">read more</div>
      </div>

      <!-- 公开内容 -->
      <div class="publicShow">
        <div class="img" @click="goGoodDetil">
          <slot name="img"></slot>
        </div>
        <div class="title" @click="goGoodDetil">
          <slot name="title"></slot>
        </div>

        <!-- 不缺货 -->
        <div class="tag1" v-if="!Outofstock">
          <span class="weight sell_count">
            <slot name="sell_count"></slot>
          </span>

          <slot name="tag"></slot>
        </div>
        <!-- 缺货 -->
        <div class="tag2" v-else>
          <slot name="tag"></slot>
        </div>

        <div class="star">
          <star :starNum="starNum"></star>
        </div>
        <div class="price">
          <span class="oldprice">
            <slot name="oldprice"></slot>
          </span>
          <span class="newprice">
            <slot name="newprice"></slot>
          </span>
        </div>
      </div>

      <div class="fixed" v-if="mode == 'fixed'">
        <div
          class="btn"
          v-if="!Outofstock && productData.cartnums == 0"
          @click="add"
        >
          {{ $t("Goods.Pages.AddToCart") }}
        </div>
        <div class="buyBtn" v-if="!Outofstock && productData.cartnums >= 1">
          <div class="sub" @click="subnum">
            <i class="iconfont icon-jianhao"></i>
          </div>
          <div class="num">
            {{ productData.cartnums }}
          </div>
          <div class="add" @click="addnum">
            <i class="iconfont icon-add"></i>
          </div>
        </div>
        <div class="btn" v-if="Outofstock">read more</div>
      </div>
    </div>
  </div>
</template>
<script>
import star from "common/star/star";
import MyIcon from "common/icon/MyIcon";
import Cart from "@/model/Cart";
import Goods from "@/model/Goods";

export default {
  data() {
    return {
      Iconconfig: {
        width: "2.5rem",
        height: "2.5rem",
        lineHeight: "2.5rem",
        color: "#494f64",
        fontSize: "1.25rem",
        boxShadow: "0 3px 5px rgba(0,0,0,.1)",
        // border: "1px solid #edeef5",
      },

      //当前商品是否已经被收藏
      isgiveLike: this.productData.fav_status == 1,

      goodDetilData: {},

    };
  },
  methods: {
    //添加进购物车
    add(e) {
      Cart.Add({
        data: {
          goods_id: this.productData.goods_id,
          product_id: this.productData.product_id,
          num: 1,
          type: "goods",
          e
        },
        succ: (res, all) => {
          this.$myPopover(e)
          this.$store.commit("updateCartData", res);
          // this.$message("addCart", all.msg);
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
      });
    },

    //收藏商品
    giveLike() {
      let goods_id = this.productData.goods_id;
      Goods.Goods.Fav({
        data: {
          goods_id,
        },
        succ: (res, all) => {
          //收藏行为
          if (res.fav_status == 1) {
            this.ishasLike = true;
            this.$message("setLike", all.msg);
          } else {
            this.ishasLike = false;
            this.$message("setLike", all.msg);
          }
        },
        fail: (res) => {},
      });
    },

    //跳转到商品详情页
    goGoodDetil() {
      this.$router.push({
        path: "/product/index",
        query: {
          product_id: this.productData.product_id,
        },
      });
    },

    // 点击全屏按钮
    openGood(product_id) {
      this.$store.commit("updateProductTk", {
        val: product_id,
        isOpen: true,
      });
    },

    //获取商品详情
    goodDetil(product_id) {
      Goods.Goods.Detail({
        data: {
          service: "goods.detail",
          product_id: product_id,
        },
        succ: (res, all) => {
          console.log(res);
          this.goodDetilData = res.goods;
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
      });
    },

    //增加数量
    addnum() {
      // this.num++
    },
    //减少数量
    subnum() {},
  },

  computed: {
    //是否已经收藏过了
    ishasLike: {
      get() {
        return this.isgiveLike;
      },
      set(value) {
        this.isgiveLike = value;
        return value;
      },
    },
  },
  props: {
    //星级
    starNum: {
      type: Number,
      default: 5,
    },

    //商品数据
    productData: {
      type: Object,
      default: () => {},
    },

    //是否缺货
    Outofstock: {
      type: Boolean,
      default: false,
    },

    //模式 hover fixed
    mode: {
      type: String,
      default: "hover",
    },
  },
  components: {
    star,
    MyIcon,
    // PopUp,
    // ProductDetails,
  },
  created() {
    this.productData.cartnums = 0;
    // this.productData.cart_nums = 0;
  },
};
</script>
<style lang='scss' scoped>
.baseBox {
  height: 100%;
}
.base {
  height: 100%;
  width: 100%;
  padding: 1.25rem;
  box-sizing: border-box;
  // background-color: white;
  // border-bottom: 1px solid #eee9f0;
  // border-right: 1px solid #eee9f0;
  position: relative;
  transition: z-index 0.3s cubic-bezier(0.28, 0.12, 0.22, 1);
  z-index: 1;
  .publicShow {
    position: relative;
    // z-index: 3;
    .img {
      cursor: pointer;
      width: 100%;
      // min-height: 100px;
      img {
        width: 100%;
      }
    }

    .title {
      cursor: pointer;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.25rem;
      overflow: hidden;
      color: #333042;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -webkit-box-orient: vertical;
      &:hover {
        color: var(--main-color);
      }
    }

    .tag1 {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      font-size: 0.75rem;
      color: #00b853;
      font-weight: 600;
      .weight {
        color: #d9d9e9;
        margin-right: 4px;
      }
    }
    .tag2 {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      font-size: 0.75rem;
      color: #ed174a;
      font-weight: 600;
    }

    .price {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      .oldprice {
        color: #d9d9e9;
        text-decoration: line-through;
        font-weight: 600;
      }
      .newprice {
        color: #d51243;
        font-weight: 600;
      }
    }
  }

  .fixed {
    margin-top: 3rem;
    background-color: white;
    display: flex;
    justify-content: center;

    .btn {
      position: absolute;
      bottom: 1.25rem;
      color: #2bbef9;
      font-size: 0.75rem;
      border-radius: 1rem;
      padding: 0.5rem 25%;
      border: 1px solid #2bbef9;
      cursor: pointer;
      transition: all 0.3s;
    }

    .buyBtn {
      position: absolute;
      bottom: 1.25rem;
      color: var(--text-color-default);
      font-size: 0.75rem;
      background-color: white;
      border-radius: 1rem;
      overflow: hidden;
      padding: 0.5rem 40%;
      border: 1px solid #c2c2d3;
      cursor: pointer;
      transition: all 0.3s;
      .sub {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background-color: #c2c2d3;
        display: flex;
        align-items: center;
        padding-left: 0.6rem;
        padding-right: 0.6rem;
        cursor: pointer;
      }

      .add {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        background-color: #ffcd00;
        display: flex;
        align-items: center;
        padding-left: 0.6rem;
        padding-right: 0.6rem;
        cursor: pointer;
      }
    }
  }

  .hidden {
    visibility: hidden;
    position: absolute;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    // z-index: 2;
    top: -1px;
    bottom: -1px;
    border: 1px solid #c2c2d3;
    left: -1px;
    right: -1px;
    margin-bottom: -3.5rem;
    background-color: white;
    display: flex;
    justify-content: center;
    transition: all 0.3s;
    opacity: 0;
    .btn {
      position: absolute;
      bottom: 1.25rem;
      color: white;
      font-size: 0.75rem;
      background-color: var(--main-color);
      border-radius: 1rem;
      padding: 0.5rem 30%;
      cursor: pointer;
      transition: all 0.3s;
    }

    .buyBtn {
      position: absolute;
      bottom: 1.25rem;
      color: var(--text-color-default);
      font-size: 0.75rem;
      background-color: white;
      border-radius: 1rem;
      overflow: hidden;
      padding: 0.5rem 30%;
      border: 1px solid #c2c2d3;
      cursor: pointer;
      transition: all 0.3s;
      .sub {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background-color: #c2c2d3;
        display: flex;
        align-items: center;
        padding-left: 0.6rem;
        padding-right: 0.6rem;
        cursor: pointer;
      }

      .add {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        background-color: #ffcd00;
        display: flex;
        align-items: center;
        padding-left: 0.6rem;
        padding-right: 0.6rem;
        cursor: pointer;
      }
    }
  }

  &:hover {
    z-index: 10;

    .hidden {
      opacity: 1;
      visibility: inherit;

      .btn {
        transform: translateY(4px);
      }
    }

    .tool {
      .hiddenBtn {
        transform: translateX(-0.25rem);
        opacity: 1;
      }
    }
  }

  .tool {
    .product-badges {
      position: absolute;
      text-align: center;
      font-size: 1rem;
      color: white;
      background-color: #2bbef9;
      line-height: 1.5;
      top: 1.125rem;
      left: 1.125rem;
      border-radius: 3px;
      z-index: 5;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    .hiddenBtn {
      transition: all 0.3s;
      opacity: 0;
      position: absolute;
      right: 0.75rem;
      top: 1rem;
      z-index: 6;

      .detil,
      .like {
        margin-bottom: 0.4rem;
        cursor: pointer;

        .ico {
          border: none;
        }

        &:hover .ico {
          background-color: var(--main-color);
          color: white !important;
        }
      }

      .haslike {
        .ico {
          background-color: var(--main-color);
          color: white !important;
        }
      }
    }
  }
}
</style>
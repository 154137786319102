<template>
  <div class="list">
    <div class="item" v-for="item in goodslist" :key="item.goods_id">
      <product-item :productData="item" >
        <template v-slot:img>
          <img v-lazy="item.image" alt="" />
        </template>
        <template v-slot:title>   
         {{$cl('name',item,$i18n.locale)}}
        </template>
        <!-- <template v-slot:tag>{{item.onSale ? '在售' : '下架' }}</template> -->
        <template v-slot:sell_count> {{$t('Product.Pages.SaleCount')}}：{{item.sell_count}} </template>
        <template v-slot:oldprice>
          {{ item.price_text }}
        </template>
        <template v-slot:newprice>
          {{ item.price_text }}
        </template>
        <template v-slot:productBadges> 15% </template>
      </product-item>
    </div>

     <new-empty class="emty" v-if="goodslist.length==0 && paginated.total+1" image="empty" :decripe="$t('Product.Msg.Empty')"></new-empty>
    <!-- <div class="emty"  v-show="goodslist.length==0 && paginated.total+1">
      当前类别没有数据！

    </div> -->
  </div>
</template>
<script>
import ProductItem from "content/product/ProductItem";
import NewEmpty from "@/components/common/empty/NewEmpty";
export default {
  data() {
    return {};
  },
  created() {
  
  },
  props: {
    goodslist:{
      type:Array
    },
    paginated:{
      type:Object
    }
  },
  components: {
    ProductItem,
    NewEmpty
  },
};
</script>
<style lang='scss' scoped>

.list {
  display: flex;
  flex-wrap: wrap;
  min-height: 150px;
  position: relative;
  &::before{
    position: absolute;
    content: '';
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid #eee9f0;
  }

  .item {
    width: 100%;
    max-width: 20%;
  }

  // .emty {
  //   border-right: 1px solid #eee9f0;
  //   border-bottom: 1px solid #eee9f0;
  // }
}
</style>